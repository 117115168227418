import axios from 'axios';
import moment from 'moment/moment';

class IntegrationEngineApi {
	constructor() {
		this.axiosInstance = axios.create({
			baseURL:
				process.env.NODE_ENV === 'production'
					? 'https://aei-7.ameroservices.dk/ie-api'
					: 'http://aei.test/ie-api',
			timeout: 60000
		});
	}

	async setToken(token) {
		this.token = token;
		this.axiosInstance.interceptors.request.use(async config => {
			config.headers['Authorization'] = 'Bearer ' + this.token;
			return config;
		});
	}

	async getIntegrations(data) {
		const params = {
			page: data.current,
			limit: data.resultsPerPage
		};
		if (data.searchTerm) {
			params.search = data.searchTerm;
		}

		return await this.axiosInstance.get('/integrations', {
			params
		});
	}

	async getIntegrationById(id) {
		return await this.axiosInstance.get(`/integrations/${id}`);
	}

	async getIntegrationLogsById(id, data) {
		const params = { limit: data.limit, page: data.page };

		if (data.searchValue) {
			params.search = data.searchValue;
		}

		if (data.status) {
			params.status = data.status;
		}

		params.from_date = moment(data.from).toISOString();
		params.status = data.status;

		if (data.type) {
			params.type = data.type;
		}

		if (data.to) {
			params.to_date = moment(data.to).toISOString();
		}

		return this.axiosInstance.get(`/integrations/${id}/logs`, {
			params
		});
	}

	async getIntegrationSettingsByCustomerId(id) {
		return this.axiosInstance.get(`/integrations/${id}/customerSettings`);
	}

	async updateIntegrationSettingsById(id, settingsToSend) {
		return await this.axiosInstance.post(`integrations/${id}/customerSettings`, { ...settingsToSend });
	}

	async getIntegrationSynchronizationsById(id, data) {
		const params = { limit: data.limit, page: data.page };

		if (data.searchValue) {
			params.search = data.searchValue;
		}

		if (data.hideEmpty) {
			params.hide_empty = 1;
		} else {
			params.hide_empty = 0;
		}

		params.from_date = moment(data.from).toISOString();

		if (data.to) {
			params.to_date = moment(data.to).toISOString();
		}

		params.sections = 20;

		return this.axiosInstance.get(`/integrations/${id}/synchronizations`, {
			params
		});
	}

	async getIntegrationDataIssuesById(id, data) {
		const params = {};

		if (data.searchValue) {
			params.search = data.searchValue;
		}
		return this.axiosInstance.get(`/integrations/${id}/issues`, {
			params
		});
	}
	async getIntegrationProductsById(id, data) {
		const params = { limit: data.limit, page: data.page };

		if (data.searchValue) {
			params.search = data.searchValue;
		}
		return this.axiosInstance.get(`/integrations/${id}/canonical`, {
			params
		});
	}

	async patchIntegrationById(id, data) {
		return await this.axiosInstance.patch(`/integrations/${id}`, data);
	}

	async patchLogDescriptionByHashkey(hashkey, description) {
		return await this.axiosInstance.patch(`/logs/errors/description`, { description, error_hash: hashkey });
	}

	async tagProductForUpdate(id, productNumber) {
		return await this.axiosInstance.post(`/integrations/${id}/canonical/tagforupdate/${productNumber}`);
	}

	async tagEverythingForUpdate(id) {
		return await this.axiosInstance.post(`/integrations/${id}/canonical/updateeverything`);
	}

	async getProductData(id, productNumber) {
		return await this.axiosInstance.get(`integrations/${id}/productData/${productNumber}`);
	}

	async getProductStocks(id, productNumber) {
		return await this.axiosInstance.get(`integrations/${id}/productStocks/${productNumber}`);
	}

	async getIntegrationTypes() {
		return await this.axiosInstance.get(`integrations/integrationTypes`);
	}

	async getCreateIntegrationFields(integrationType) {
		return await this.axiosInstance.get(`integrations/createIntegrationFields/${integrationType}`);
	}

	async createIntegration(data) {
		const response = await this.axiosInstance.post(`integrations/createIntegration`, { data });
		return response.data;
	}

	async executeSyncIntegration(id) {
		const response = await this.axiosInstance.get(`integrations/execute-integration/${id}`);
		return response.data;
	}

	async deleteCanonical(id) {
		try {
			return await this.axiosInstance.get(`integrations/${id}/deleteCanonical`);
		} catch (error) {
			return { message: error.response.data, status: error.response.status };
		}
	}

	async resetHashState(id) {
		return await this.axiosInstance.get(`integrations/${id}/resetHashState`);
	}

	async testConnectivityBySettings(data) {
		const response = await this.axiosInstance.post(`integrations/testConnectivity`, data);
		return response.data;
	}

	async testConnectivityById(id) {
		const response = await this.axiosInstance.get(`integrations/${id}/testConnectivity`);
		return response.data;
	}

	async createCanonical(id, correctWarehouse) {
		return await this.axiosInstance.post(
			`integrations/${id}/create-canonical-stock-on-source-customer-based-on-own-warehouse/${correctWarehouse}`
		);
	}
}

const integrationEngineApiServiceInstance = new IntegrationEngineApi();

export default integrationEngineApiServiceInstance;
